.errorPage {
  height: 100vh;
  width: 100vw;
  position: fixed;
  left: 0;
  top: 0;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: white;
  background-image: url(../../assets/images/error/shutterstock_774749455.jpg);
  z-index: 999;
}
