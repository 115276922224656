.loginCard {
  /* width: 500px; */
  min-height: 100vh;
  /* margin: auto; */
  /* background: url(../../assets/images/2924633.jpg)
    no-repeat center; */
  background-size: 100% 100%;
  /* background: #212529; */
  /* padding-top: 10%; */
  position: relative;
}
.card_login {
  background-color: #3d5a80 !important;
  width: 500px;
  /* margin: 0 auto; */
  margin-top: 8vh;
  margin-left: 60vw;
  position: relative;
  border: none;
  color: #fff;
}
.card_login input {
  color: white;
}

.loginText {
  min-height: 100vh;
  background-image: url(../../assets/images/login/background-login.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #24bcd6;
}

.loginDescription {
  margin-top: 9%;
  margin-left: 15vw;
  max-width: 15rem;
  color: white;
}

.loginForm {
  background-color: white;
}

.loginDescriptionImage {
  max-width: 100%;
  height: 50vh;
}
