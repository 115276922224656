.log__container h4 {
  /* margin-left: -290px !important;
    width: calc(100% + 290px) !important; */
  background-color: #45bf86;
  /* background-color: #45bf86; */
  padding: 20px;
  border-radius: 20px;
  color: #fff;
}
.rs-timeline-item-dot {
  top: 0px;
}
.rs-timeline-align-left .rs-timeline-item-dot {
  left: -3px;
}
