.album img {
    object-fit: cover;
    margin: 0.25%;
}

.type-0-image-0,
.type-0-image-2,
.type-0-image-1,
.type-3-image-2,
.type-3-image-3,
.type-3-image-4,
.type-4-image-1,
.type-4-image-2,
.type-4-image-3 {
    width: 32.83%;
    aspect-ratio: 1/1;
}

.type-0-image-3,
.type-0-image-4,
.type-3-image-0,
.type-3-image-1 {
    width: 49.5%;
    aspect-ratio: 1/1.32;
}

.album {
    position: relative;
    border: 2px solid #c2baa6;
    background-color: white;
    border-radius: 0.5rem;
    display: flex;
    flex-wrap: wrap;
    padding: 1rem;
}

.album-1 {
    position: relative;
    border: 2px solid #c2baa6;
    background-color: white;
    border-radius: 0.5rem;
    display: flex;
    padding: 1rem;
    width: 100%;
}

.album-1-1 {
    width: 66.66%;
    padding-right: 0.25rem;
    aspect-ratio: 2/3;
}

.album-1-2 {
    width: calc(33.33% - 0.125rem);
    height: fit-content;
    display: flex;
    flex-direction: column;
}

.album-1-2 img:not(:first-child) {
    padding-top: 0.25rem;
}

.album-1-2 img {
    object-fit: cover;
}

.type-1-image-0 {
    width: 100%;
    aspect-ratio: 2/3;
    object-fit: cover;
}
.type-1-image-1,
.type-1-image-2,
.type-1-image-3 {
    width: 100%;
    aspect-ratio: 1/1;
}

.type-2-image-0,
.type-2-image-1,
.type-2-image-2,
.type-2-image-3 {
    width: 49.5%;
    aspect-ratio: 1/1;
}

.type-4-image-0 {
    width: 99.5%;
    aspect-ratio: 99.5/65.64;
}

.fake-more-content {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    color: white;
    font-size: 2vw;
    bottom: calc(1rem + 0.25%);
    right: calc(1rem + 0.25%);
    background: rgba(0, 0, 0, 0.205);
}

.fake-more-content.card-type-1 {
    bottom: 1.375rem;
    right: calc(1.125rem);
}
