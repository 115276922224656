.autojoin__answer {
  height: 8rem;
  overflow-y: auto;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

.autojoin__label {
  font-size: 0.8rem;
  color: #353535;
}

/* .group_membernumber {
  font-size: 0.9rem;
  color: #353535;
  font-weight: bold;
} */
.list-result {
  text-decoration: none;
  color: #353535;
}
a:hover,
a:active {
  text-decoration: none;
  color: #353535;
}
