.reportButton {
  position: fixed;
  /* bottom: 1rem; */
  bottom: 55px;
  right: 1.5rem;
  z-index: 10000;
  /* padding: 0.5rem; */
}

.rs-btn-icon.rs-btn-lg {
  /* padding: 14px; */
}
