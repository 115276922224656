.ppa__pageTitle {
  color: rgb(95, 95, 95) !important;
  font-weight: bold;
  text-decoration: none !important;
}

.ppa__pagePostBody {
  height: 25rem;
  border: 1px solid #f18f01 !important;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.ppa__pagePost {
  width: 100%;
  height: 12rem;
}

.pageImage {
  width: 100%;
  height: 12rem;
  object-fit: cover;
  object-position: center;
  border-radius: 5px;
}

.ppa__pagePostDescription {
  margin-top: 0.5rem;
  font-size: 0.7rem;
  text-align: justify;
}

.ppa_pagePostStats {
  /* position: absolute;
  bottom: 1rem; */
}

.ppa_statsNumber {
  margin-left: 0.3rem;
  font-size: 0.8rem;
}
.random__text .rs-checkbox-wrapper{
  top:3px;
  left: 15px
}