.infoBody {
  margin: auto;
}

.userprofileImage {
  width: 10rem;
  height: 10rem;
  max-width: 100%;
  border-radius: 0.3rem;
}

.infoAvatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
