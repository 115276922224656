.pageDropdown button {
  /* background-color: #f5ecd7; */
  background-color: rgba(194,186,166,0.2);

}
.pageDropdown button:hover {
  /* background-color: #f5ecd7; */
  background-color: rgba(194,186,166,0.2);

}

.pageDropdown ul {
  background-color: #f7f1e5;
}

.pageDropdown .rs-dropdown-item-focus {
  background-color: #f7e4ba !important;
}
