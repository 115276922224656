.blackLink {
  color: black !important;
  text-decoration: none;
}

.blackLink:hover {
  text-decoration: none;
}

.oneLine {
  white-space: nowrap;
}
