.rs-picker-toggle-textbox {
  /* background-color: #f5ecd7; */
  background-color: rgba(194, 186, 166, 0.2);

  border: #45bf86;
}

.form-select:focus {
  border-color: #45bf86;
  box-shadow: 0 0 0 3px rgb(73 135 53 / 25%);
  /* 3px solid rgb(73 135 53 / 25%); */
}

.select-item-image {
  width: 40px;
  aspect-ratio: 1/1;
  border-radius: 50%;
  object-fit: cover;
}

.select-account-status-image {
  width: 20px;
  aspect-ratio: 1/1;
  object-fit: cover;
}
/* border: 1px solid #45bf86 !important; */
