@import url(https://fonts.googleapis.com/css?family=Oxygen:300,700);
html,
body {
  width: 100%;
  height: auto;
  margin: 0;
  font-family: SourceSans;
  /* font-family: "Oxygen", san-serif; */
}

@font-face {
  font-family: SourceSans;
  src: url(../../../assets/fonts/source-sans/SourceSans3-Regular.ttf);
}

.container {
  /* margin-left: -290px; */
  /* width: calc(100% + 290px + 55px); */
  display: flex;
  /* flex-direction: row; */
  align-items: center;
  justify-content: center;
  /* padding-top: 50px !important; */
  /* width: 850px;
  height: 400px;
  margin: 5% auto; */
}
.container h2 {
  font-weight: bold;
}

.container .table-price {
  background: #f3f2f6;
  width: 20rem;
  height: 10rem;
  /* width: 250px; */
  /* height: 350px; */
  margin: 1% 1.5%;
  border-radius: 10px;
  /* float: left; */
  position: relative;
  box-shadow: 0px 0px 10px #594083;
  transition: 0.3s ease-in-out;
  cursor: pointer;
}
.container .table-price:hover {
  box-shadow: 5px 5px 5px #594083;
}
.container .table-price .pic-item {
  width: 100%;
  height: 60%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.items-container {
  padding-top: 40px;
}
.container .table-price .pic-item img {
  width: 100%;
  height: 100%;
}
.container .table-price .pic-item svg {
  fill: #833500;
  margin: 5% 50px;
}
.container .table-price .best-choice {
  background: #f5a623;
  width: 50%;
  height: 25px;
  font-family: "Oxygen", san-serif;
  font-size: 0.9em;
  font-weight: 700;
  text-align: center;
  line-height: 1.8;
  text-transform: uppercase;
  color: #f3f2f6;
  margin: 0 25%;
  top: 56%;
  border-radius: 10px;
  position: absolute;
  left: 0;
  z-index: 99;
}
.container .table-price .description {
  background: #8fbf9f;
  /* background: #833500; */
  width: 100%;
  height: 100%;
  border-radius: 10px;
  /* border-bottom-right-radius: 10px; */
  /* position: relative; */
}
.container .table-price .description .title {
  width: 100%;
  font-family: "Oxygen", sans-serif;
  font-size: 1.3rem;
  font-weight: 700;
  text-align: center;
  color: #f3f2f6;
  /* text-transform: uppercase; */
  top: 10%;
  /* display: block; */
  /* position: absolute; */
}
.container .table-price .description .price {
  width: 100%;
  font-family: "Oxygen", sans-serif;
  font-size: 1.5em;
  font-weight: 300;
  text-align: center;
  color: #353535;
  /* color: #f18f01; */
  /* top: 35%; */
  /* display: block; */
  /* position: absolute; */
  font-weight: bold;
}

.cardHeading {
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  color: white;
  font-size: 1.3rem;
  /* background-color: #594083; */
}

.priceCard:hover {
  box-shadow: 5px 5px 5px #594083;
  transition: all 0.7s;
}

.container .selected {
  height: 400px;
  top: -25px;
}

.priceCard {
  position: relative;
  border-radius: 1rem;
  width: 17rem;
  background-color: rgb(253, 245, 245);
  padding-bottom: 1.5rem;
  cursor: pointer;
  transition: all 0.7s;

  /* border: 1px solid black; */
}

.priceTag {
  position: relative;
  bottom: 1.8rem;
  background-color: rgb(73, 102, 170);
  padding: 0.5rem 1.5rem;
  color: white;
  border-radius: 2rem;
  font-size: 1.3rem;
  /* top: 1rem; */
}
