.addImageButton {
  position: absolute;
  margin-top: 1rem;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 999;
}

.cardImageType {
  cursor: pointer;
  border: 1px solid #c2baa6 !important;
}

.cardImageType.selected {
  border: 1px solid #45bf86 !important;
}

.gridDemo1 {
  width: 100%;
  /* height: 600px; */
  height: 100%;
  /* background-color: #ebe2cd; */
  background-color: rgba(194, 186, 166, 0.2);

  display: grid;
  gap: 5px;
  /* justify-content: stretch; */
}

.gridDemo1 > * {
  background-color: #c2baa6;
}

/* grid1 */

.gridDemo1__grid4 {
  grid-column: 1/4;
  grid-row: 2/4;
  /* align-content: stretch; */
}
.gridDemo1__grid5 {
  grid-column: 4/7;
  grid-row: 2/4;
  /* align-content: stretch; */
}
.gridDemo1__grid1 {
  grid-column: 1/3;
  grid-row: 1;
}
.gridDemo1__grid2 {
  grid-column: 3/5;
  grid-row: 1;
}
.gridDemo1__grid3 {
  grid-column: 5/7;
  grid-row: 1;
}

/* grid demo2 */

.gridDemo2__grid1 {
  grid-column: 1/3;
  grid-row: 1/4;
}

.gridDemo2__grid2 {
  grid-column: 3/4;
  grid-row: 1/2;
}
.gridDemo2__grid3 {
  grid-column: 3/4;
  grid-row: 2/3;
}
.gridDemo2__grid4 {
  grid-column: 3/4;
  grid-row: 3/4;
}

/* grid3 */
.gridDemo3__grid1 {
  grid-column: 1;
  grid-row: 1;
}
.gridDemo3__grid2 {
  grid-column: 2;
  grid-row: 1;
}
.gridDemo3__grid3 {
  grid-column: 1;
  grid-row: 2;
}
.gridDemo3__grid4 {
  grid-column: 2;
  grid-row: 2;
}

/* grid4 */
.gridDemo4__grid1 {
  grid-column: 1/6;
  grid-row: 1/3;
}
.gridDemo4__grid2 {
  grid-column: 6/12;
  grid-row: 1/3;
}
.gridDemo4__grid3 {
  grid-column: 1/4;
  grid-row: 3/4;
}
.gridDemo4__grid4 {
  grid-column: 4/8;
  grid-row: 3/4;
}
.gridDemo4__grid5 {
  grid-column: 8/12;
  grid-row: 3/4;
}

/* grid5 */
.gridDemo5__grid1 {
  grid-column: 1/12;
  grid-row: 1/3;
}

.gridDemo5__grid2 {
  grid-column: 1/4;
  grid-row: 3/4;
}
.gridDemo5__grid3 {
  grid-column: 4/8;
  grid-row: 3/4;
}
.gridDemo5__grid4 {
  grid-column: 8/12;
  grid-row: 3/4;
}

.deleteImageButton {
  /* position: absolute;
  right: 1rem;
  top: 1rem; */
  display: none;
}

.PEF_Number {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.25);

  text-align: center;
  font-size: 2.5rem;
  color: white;
}

.rs-dropdown .rs-dropdown-toggle.rs-btn.rs-btn-default {
  width: 100%;
}

.rs-dropdown .rs-dropdown-menu {
  width: 100%;
}
