body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  /* font-family: MacFont; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* overflow: hidden; */
}

body::-webkit-scrollbar {
  display: none;
}

@font-face {
  font-family: MacFont;
  src: url(https://fonts.gstatic.com/s/oxygen/v15/2sDcZG1Wl4LcnbuCJW8zZmW5O7w.woff2) format("woff2");
}

code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace; */
  /* font-family: MacFont; */
}
