.rs-checkbox-wrapper .rs-checkbox-inner:before {
  background-color: white;
}

.rs-checkbox-checked .rs-checkbox-wrapper .rs-checkbox-inner:before,
.rs-checkbox-indeterminate .rs-checkbox-wrapper .rs-checkbox-inner:before {
  border: #45bf86;
  background-color: #45bf86;
}

.rs-checkbox [type="checkbox"]:focus-visible ~ .rs-checkbox-inner:before {
  box-shadow: 0 0 0 3px rgb(73 135 53 / 25%);
}

label:hover .rs-checkbox-wrapper .rs-checkbox-inner:before {
  border-color: #45bf86;
}

.rs-checkbox-wrapper:before {
  border: 1px solid #45bf86;
}

.rs-checkbox-wrapper .rs-checkbox-inner:after {
  /* border-color: #45bf86; */
  border: 1px solid #45bf86;
}

.sortingNumber {
  cursor: pointer;
  font-size: 1.2rem;
  /* margin-bottom: 0.3rem; */
}

.bg-primary {
  background-color: #45bf86 !important;
}
