.p__modal {
  display: block;
  overflow: auto;
  margin: auto;
  position: fixed;
  top: 0;
  background-color: rgba(0, 0, 0, 0.4);
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
}
.p__modal__header img {
  width: 20px;
  height: 20px;
}
.p__modal__wrapper {
  background: #fff;
  border-radius: 13px;
  padding: 20px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 1000px;
  margin: auto;
}
.p__modal__container {
  position: relative;
}
.p__modal__closebutton {
  position: absolute;
  right: 8px;
  top: 8px;
}
.p__modal__body {
  margin-top: 20px;
}

.p__modal__body img {
  width: 50px;
}
.confirm__btn {
  height: 30px;
  font-size: 14px;
  line-height: 14px;
  padding: 6px 12px;
  border-radius: 9px;
  background-color: white;
  color: #000000;
  border: 1px solid #000000;
  box-shadow: 0 2px 0 0 #000000;
  background-color: #fce4c0;
  color: #815104;
  box-shadow: 0 2px 0 0 #cc8b24;
  cursor: pointer;
  transition: all 0.2s;
  margin-bottom: 3px;
  font-weight: bold;
  text-align: center;
}
.confirm__btn:hover {
  /* background-color: #f7f7f8; */
  box-shadow: 0 3px 0 0 #cc8b24;
  transform: translateX(-1px);
}
.payment__info {
  font-style: italic;
  font-size: 14px;
}
.bank__info img {
  width: 30%;
}
input[type="radio"],
input[type="checkbox"] {
  width: 1.5em;
  height: 1.5rem;
  accent-color: black;
}

.moneyAfterDiscount {
  color: red;
  text-decoration: line-through red;
  margin-right: 1rem;
}
