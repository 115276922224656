.forgotPassword {
  background-image: url(../../assets/images/macos-big-sur-wallpapers-wallpaper-cave.png);
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  padding-top: 20vh;
}

.cardForgotPassword {
  position: relative;
}
