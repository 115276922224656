.euidfromgroup {
  background: url(../../../../assets/images/vin-bg.jpg);
  min-height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
}

.euidfromgroup__groupInput {
  width: 100%;
  margin: 0 auto;
  border: none !important;
}

.euidfromgroup__groupInput__name {
  font-size: 14px;
  color: rgb(55, 65, 81);
  margin-bottom: 0 !important;
}

.euidfromgroup__groupInput__id {
  color: rgb(55, 65, 81);
  font-size: 10px;
  margin: 0 !important;
}

.euidfromgroup__groupInput__button {
  margin-top: 1rem;
  width: 100% !important;
  /* background-color: #1f2937 !important; */
  color: white !important;
  font-weight: 700 !important;
  font-size: 16px !important;
}

._pagination {
  /* position: absolute;
  bottom: 1rem;
  width: 100%; */
}
.group_membernumber {
  font-size: 0.9rem;
  color: #353535;
  font-weight: bold;
}
.rs-form-horizontal .rs-form-group .rs-form-control-wrapper {
  width: 100%;
}
.rs-form-control-wrapper > .rs-input,
.rs-form-control-wrapper > .rs-input-number {
  width: 100%;
}
