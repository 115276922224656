.card__body {
  /* background-color: #ebe2cd; */
  background-color: rgba(194, 186, 166, 0.2);
  color: #353535;
  border: none;
}

.card_image {
  max-width: 100%;
  max-height: 0rem;
  min-height: 8rem;
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 0.5rem;
}

.cardCategory {
  width: 30px;
  height: 30px;
  border-radius: 5px;
}

.cardDescriptionBody {
  height: 4rem;
  max-height: 4rem;
  padding-top: 0.6rem;
  margin-bottom: 1rem;
  font-size: 0.8rem;
  color: gray;
  overflow: hidden;
  text-overflow: ellipsis;
}

.title-multi-line {
  display: -webkit-box;
  max-width: 100%;
  height: 3rem;
  /* max-width: 200px; */
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-bottom: 0;
}
