.extensionMenu {
  height: 60px;
  /* height: fit-content; */
  /* width: 9%; */
  width: 12%;
  color: #fff;
  background-color: #f2ae14;
  /* background-color: rgba(194, 186, 166, 0.2); */

  position: fixed;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  padding: 0.5rem;
  /* border-bottom-left-radius: 0.5rem; */
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
  z-index: 999;
  transition: width 0.3s ease-out;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

.extension_text {
  /* font-size: 0.7rem; */
  font-size: 0.9rem;
  margin-left: 10px;
}
.extension_text_name {
  /* font-size: 0.7rem; */
  font-size: 1rem;
  margin-left: 10px;
  font-weight: bold;
}
.showless div:not(:first-child) {
  display: none;
}
.extensionMenu:has(.showless) {
  /* width: 4%; */
  width: 55px;
}
.extensionMenu:has(.showless):hover {
  width: 12%;
}
.extensionMenu:has(.showless):hover .showless div:not(:first-child) {
  display: inline-block;
}
.rs-dropdown .rs-dropdown-menu {
  width: 100%;
  max-height: 24rem;
  overflow-y: scroll;
}
.loading-modal .rs-modal-content {
  background: transparent !important;
}

.rs-modal-open {
  overflow: auto;
}

.navigateLogin {
  cursor: pointer;
  text-decoration: underline;
}
