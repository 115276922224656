.postsRemover__linkToPost {
  /* font-size: 0.7rem; */
  color: black;
  text-decoration: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 15rem;
}

.postsRemover__username {
  /* font-size: 95%; */
  /* margin-left: 0.25rem; */
  color: red;
  text-decoration: none !important;
}
