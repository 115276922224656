.ref__info__item {
  width: 23%;
  border-style: dashed;
  border-radius: 0.5rem;
  padding-bottom: 1rem;
  padding-top: 1rem;
  border-width: 1px;
  border-color: #8392ab;
  text-align: center;
}
.ref__code__details {
  width: 40%;
}
.ref__code__copy {
  border-style: dashed;
  border-radius: 0.5rem;
  padding-bottom: 1rem;
  padding-top: 1rem;
  border-width: 1px;
  border-color: #8392ab;
  padding-left: 0.5rem;
}
.ref__code__copy p {
  margin-bottom: 0.3rem !important;
}
.ref__code__copy input {
  width: 70%;
  padding: 5px 10px;
  border-radius: 4px;
  background-color: #e9ecef;
  opacity: 1;
}
.ref__code__copy__input button {
  margin-left: 10px;
  padding: 5px 10px;
  border-radius: 4px;
  border: 1px solid;
  border-color: #8392ab;
  color: #8392ab;
}
.ref__code__copy__input button:hover {
  transform: translateY(-1px);
}
.ref__code__use {
  padding-left: 20px;
  width: 60%;
}
.icon__container {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-image: linear-gradient(310deg, #212229, #212529);
  display: flex;
  justify-content: center;
  align-items: center;
}
