.home__body {
  position: relative;
  /* min-height: calc(100vh - 50px); */
  min-height: calc(100vh - 60px);
  /* background-color: white; */
  left: calc(70px + 220px);
  width: calc(100% - 70px - 220px);
  /* width: calc(100% - 70px); */
  /* left: 70px; */
  padding: 4.5rem 5% 4.5rem 5%;
}

.home__body:has(.loginCard) {
  width: 100%;
  padding: 0;
  left: 0;
  max-height: 100vh !important;
  position: relative;
}

.home__body.noLogin {
  left: 0;
  width: 100%;
}

.home__body.landing {
  padding: 0;
}
/* .home__body.have2ndSidebar {
  left: calc(70px + 220px);
  width: calc(100% - 70px - 220px);
} */
