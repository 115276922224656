.sidebar {
  position: fixed;
  left: 0;
  top: 0;
  width: 55px;
  height: 100vh;

  /* border-right: 1px solid aliceblue; */
  /* background-color: #353535; */
  /* background-color: #0022bb; */
  background-color: #096a9b;

  z-index: 1000;
}

.rs-btn-subtle:hover {
  background-color: #d9d9d973 !important;
}
.rs-btn .rs-btn-default:active {
  background-color: #d9d9d9;
}

.toolHeader__avatar {
  width: 30px;
  height: 30px;
  cursor: pointer;
  border-radius: 50%;
}

.dropdown-sidebar {
  width: fit-content !important;
}

.sidebarMenu p {
  font-size: 0.9rem;
}
