.cardLikePage {
  border-radius: 1rem;
  overflow: hidden;
  border: 3px solid #ebe2cd;
  cursor: pointer;
  /* border: none !important; */
}

.carousel__uploadVideoButton {
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  /* top: 1rem; */
}

.default-carousel-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: bottom;
  border: 0;
  max-height: 50vh;
}

.carousel-placeholder-image {
  width: 100%;
  aspect-ratio: 1/1;
  object-fit: cover;
  border: 0px;
}

.selectedThumb {
  border: 3px solid #45bf86 !important;
}

.carouselThumbnailList {
  max-width: 100%;
  border: 2px solid #45bf86;
  border-radius: 0.3rem;
  padding: 0.3rem;
}
