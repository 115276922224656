.app {
  background-color: #fff;
  /* background-color: #ebe2cd; */
  min-height: 100vh;
  color: #353535;
}

iframe#webpack-dev-server-client-overlay {
  display: none !important;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body > .skiptranslate {
  display: none;
}

.goog-te-banner-frame.skiptranslate {
  display: none !important;
}
body {
  top: 0px !important;
}
@media print {
  #google_translate_element {
    display: none;
  }

  #google-translate-widget {
    display: none;
  }
}

#google_translate_element {
  position: absolute;
  /* z-index: 999999; */
  left: 3rem;
  bottom: 0;
  /* background-color: white; */
  padding: 0.5rem;
}

#google-translate-widget {
  position: absolute;
  left: 3rem;
  bottom: 0;
  padding: 0.5rem;
}
