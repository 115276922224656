.toolFooter {
  /* position: absolute; */
  position: fixed;
  bottom: 0px;
  /* bottom: 0; */
  /* height: 100px; */
  /* width: calc(100% - 55px - 250px);
   */
  background-color: rgba(194, 186, 166, 0.8);
  padding: 0.5rem 5%;
  backdrop-filter: blur(10px);
  z-index: 500;
}

.toolFooter__footerLogo {
  width: 50px;
  height: auto;
}

.toolFooter__link {
  font-size: 1rem;
  color: #353535;
  text-decoration: none;
}

.toolFooter__link:hover {
  text-decoration: none;
  /* font-weight: bold; */
}

.footerThankyouText {
  font-size: 1.3rem;
}

.footerText {
  color: #7d8aa8;
  font-size: 0.8rem;
  text-decoration: none;
  cursor: pointer;
}

.footerText:hover {
  color: #7d8aa8;
}

@media only screen and (max-width: 1080px) {
  span {
    font-size: 0.85rem;
  }
}
