.home__menuItems {
  display: flex;
  flex-wrap: wrap;
  /* flex-shrink: 3; */
  /* gap: 1rem; */
}

.home__menuItem {
  width: 19%;
  margin-top: 0.7rem;
}

.categoryHeading {
  /* background-color: #1f4373; */
  color: #1f4373;
  padding: 0.2rem 1rem;
  font-size: 1.5rem;
  /* margin-right: 0.4rem; */
}

@media screen and (max-width: 1024px) {
  /* .home__menuItem {
    width: 24%;
  } */
}
