@font-face {
  font-family: "Poppins";
  src: url(../../assets/fonts/Poppins/Poppins-Regular.ttf);
}
@font-face {
  font-family: "Raleway";
  src: url(../../assets/fonts/Manrope/Manrope-VariableFont_wght.ttf);
}
@font-face {
  font-family: "Manrope";
  src: url(../../assets/fonts/Manrope/static/Manrope-Regular.ttf);
}

.landing {
  /* font */
  font-family: "Raleway";
}

.landing__section1 {
  padding: 2vh 12vw 0 12vw;
  background: url(../../assets/images/landing-page/header-background.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}

.landing__section1__header__logo {
  width: 3.5vw;
  height: auto;
  cursor: pointer;
}

.landing__section1__header__headerLink {
  color: #ffffff !important;
  text-decoration: none;
  font-size: 2vh;
}

.landing__section1__body {
  margin-top: 10vh;
  color: #ffffff;
}

.landing__section1__body__h1 {
  font-size: 7vh;
  font-weight: 700;
  margin-bottom: 5vh;
}

.landing__section1__body__detailText {
  font-size: 2vh;
  margin-bottom: 10vh;
  text-align: justify;
}

.headerImage {
  position: relative;
  right: -5vw;
  height: 80vh;
}

.landing__section1__body__registerbutton {
  font-size: 3vh;
  font-weight: 700;
  background-color: #004e86;
  padding: 0.5rem 2rem;
  border-radius: 4rem;
  cursor: pointer;
}

.landing__section1__body__loginbutton {
  font-size: 3vh;
  font-weight: 700;
  cursor: pointer;
}

.landing__section2__body {
  width: 32%;
  margin-top: 4.5vh;
}

.landing__section2__body.listSelect {
  padding: 2vw;
  border: 1px solid rgb(209, 209, 209);
  border-radius: 1rem;
  background-color: rgb(243, 243, 243);
  cursor: pointer;
}

.landing__section2 {
  padding: 3vh 8vw;
}

.landing__section2__body__header {
  color: #111b34;
  font-size: 5.5vh;
  font-weight: 700;
  transition: all 0.2s;
}

.landing__section2__body__header1 {
  color: #111b34;
  font-size: 3vh;
  font-weight: 700;
  transition: all 0.2s;
}

.landing__section2__body__contenttext {
  color: #929197;
  font-size: 2vh;
  text-align: justify;
  transition: all 0.2s;
}

.landing__section2__body__check {
  background-color: #004e86;
  border-radius: 50%;
  min-width: 6vh;
  min-height: 6vh;
  height: 6vh;
  width: 6vh;
  font-size: 2vh;
  color: white;
  transition: all 0.2s;
}

.selected.landing__section2__body {
  color: white;
  background-color: #004e86;
}

.selected .landing__section2__body__header1 {
  color: white;
}

.selected .landing__section2__body__check {
  background-color: white;
  color: #004e86;
}

.selected .landing__section2__body__contenttext {
  color: white;
}

.selected {
  transition: all 0.2s;
}

/*  */
.landing__section3 {
  padding: 0 7vw;
  background: url(../../assets/images/landing-page/section3-background.svg);
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 5vh;
}

.landing__section3__header {
  font-weight: 700;
  font-size: 6vh;
  margin: 6vh auto;
}

.landing__section3__content__item {
  margin-top: 3vh;
  gap: 10vw;
}

.landing__section3__content__card {
  border: 1px solid #e7e7e7;
  border-radius: 3rem;
  padding: 2rem;
  background-color: white;
}

.landing__section3__content__image {
  width: 35vw;
  height: auto;
  max-width: 35vw;
}

.landing__section3__content__card__icon {
  width: 10vw;
}

.landing__section3__content__card__header {
  font-size: 4vh;
  font-weight: 700;
}

.landing__section3__content__card__textcontent {
  font-size: 2.2vh;
  color: #929197;
}

.landing__section3__newApplication {
  margin-top: 20vh;
}

.landing__section3__newApplication__header {
  font-weight: 700;
  font-size: 7vh;
  margin-bottom: 8vh;
}

.carouselCard {
  /* border: 1px solid black; */
  padding: 1rem;
  background-color: #dbdbdb;
  border-radius: 1rem;
}

.carouselCard__header {
  margin-top: 1.5rem;
  font-size: 2.5vh;
  font-weight: bold;
}

.carouselCard__button {
  background-color: #004e86 !important;
  padding: 0.8rem 2rem;
  border-radius: 3rem;
  font-size: 1.4rem;
  max-width: 100%;
}

.carouselItem {
  width: 100%;
  object-fit: cover;
  height: 25vh;
}

.landing__section3__sponsor {
  margin-top: 5vh;
  display: flex;
  justify-content: center;
}

.sponsorImage {
  width: 60vw;
  height: auto;
}

.landing__section4 {
  padding: 0 7vw;
}

.landing__section4__header {
  font-size: 5vh;
  font-weight: 700;
  margin-bottom: 5vh;
}

.landing__section4__item {
  position: relative;
  border-radius: 1.25rem;
  width: 24vw;
  margin-top: 5vh;
  border: 1px solid #e9e9e9;
  height: 80vh;
}

.landing__section4__cardImage {
  width: 100%;
  height: 40vh;
  object-fit: cover;
  border-top-left-radius: 1.25rem;
  border-top-right-radius: 1.25rem;
}

.helpingLogo {
  width: 100%;
  height: auto;
}

.landing__section4__item__header {
  /* margin-top: 1.5vh; */
  font-size: 3vh;
  font-weight: 700;
  overflow: hidden;
  text-overflow: ellipsis; /* Sử dụng elipsis khi văn bản tràn bố cục */
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Hiển thị tối đa 2 dòng */
  -webkit-box-orient: vertical;
}

.landing__section4__item__content {
  position: absolute;
  bottom: 0;
  font-size: 2vh;
  padding: 1.25rem 2rem;
}

.landing__section4__item__description {
  overflow: hidden;
  text-overflow: ellipsis; /* Sử dụng elipsis khi văn bản tràn bố cục */
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Hiển thị tối đa 2 dòng */
  -webkit-box-orient: vertical;
}

.landing__section5 {
  padding: 10vh 7vw;
}

.landing__section5__header {
  font-weight: 700;
  font-size: 7vh;
}

.landing__section5__item {
  border: 1px solid #d3d3d3;
  padding: 1.5rem;
  border-radius: 0.5rem;
  gap: 5vw;
}

.landing__section5__itemHeader {
  font-weight: 700;
  font-size: 3vh;
}

.landing__section5__itemDescription {
  font-size: 2.2vh;
  color: #929197;
}

.landing__section5__itemImage {
  width: 7vw;
  height: auto;
}

.landing__section6 {
  padding: 10vh 7vw;
}

.landing__section5__headerDescription {
  font-size: 2.5vh;
  color: #929197;
}

.landing__section5__header {
  font-size: 3.3rem;
  text-align: center;
}

.landing__section6__header {
  font-weight: 700;
  font-size: 7vh;
}

.landing__section6__item {
  width: 40vw;
  margin-top: 5vh;
}

.landing__section6__itemComment {
  font-size: 2vh;
  margin-top: 2vh;
  color: #929197;
}

.landing__section6__itemName {
  font-size: 2vh;
  margin-top: 2.5vh;
  font-weight: 700;
}

.landing__footer {
  background: no-repeat
    url(../../assets/images/landing-page/footer-background.svg);
  background-size: cover;
  padding: 5vh 7vw;
  color: #ffffff;
}

.landing__footer__column {
  width: auto;
}

.landing__footer__heading {
  font-size: 3vh !important;
  font-weight: bold;
}

.landing__footer__column p {
  font-size: 1.8vh;
}

.landing__footer__column a {
  font-size: 1.8vh;
  text-decoration: none;
  color: #ffffff;
}

.checkLegit {
  width: 7vw;
  height: auto;
}

.socialLink svg {
  color: white;
  font-size: 3vh;
  background-color: black;
  border-radius: 50%;
  /* padding: 0.2rem; */
}

@media screen and (max-width: 768px) {
  .headerImage {
    display: none;
  }

  .landing__section2__body {
    width: 100%;
    margin-top: 0;
  }

  .landing__section2__body.listSelect {
    padding: 1rem;
  }

  .landing__section4__item {
    width: 100%;
    height: 60vh;
  }

  .landing__section1 {
    height: 100vh;
    padding: 2vh 7vw;
  }

  .landing__section1__header__headerLink {
    color: #004e86 !important;
    text-decoration: none;
    font-size: 1.5rem;
  }

  .landing__section3__content__image {
    width: 100%;
    height: auto;
    max-width: 100%;
    /* display: none; */
  }

  .landing__section1__body {
    margin-top: 7vh;
  }

  .landing__section1__body__loginbutton {
    color: #004e86;
    font-size: 2vh;
    background-color: #ffffff;
    padding: 0.5rem 2rem;
    border-radius: 4rem;
    cursor: pointer;
  }

  .landing__section1__body__h1 {
    font-size: 4vh;
    margin-bottom: 2vh;
  }

  .landing__section1__body__registerbutton {
    font-size: 2vh;
  }

  .landing__section3__header {
    font-size: 4vh;
  }

  .landing__section3__content__card__header {
    font-size: 2.5vh;
  }

  .landing__section3__content__card__textcontent {
    font-size: 2vh;
  }

  .landing__section3__newApplication__header {
    font-size: 4vh;
  }

  .landing__section6__item {
    width: 100%;
  }

  .landing__section6 {
    padding-top: 0;
  }

  .landing__section5 {
    padding: 3rem 7vw;
  }

  .landing__section1__header__logo {
    width: 3rem;
  }

  .landing__section6__header {
    font-size: 2rem;
  }

  .landing__section5__itemImage {
    width: 25vw;
  }
}
