.tutorial__menuItem {
  width: 24%;
  margin-top: 0.7rem;
}

.multi-line {
  display: -webkit-box;
  max-width: 100%;
  height: 3rem;
  /* max-width: 200px; */
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
