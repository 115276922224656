.admin__tool__container {
  /* margin-left: -290px;
    width: calc(100% + 290px); */
}
.rs-modal-content {
  background-color: #fcf9f2;
}
.select__picker {
  background-color: #fff !important;
}
