.secondSidebar {
  position: fixed;
  left: 55px;
  width: 250px;
  height: 100vh;
  background-color: #1a9bbf;
  /* background-color: #0442bf; */
  /* background-color: #f5ecd7; */
  z-index: 600;
  opacity: 1;
  transition: opacity 0.5s ease;
  box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}
.hideSecondSidebar {
  position: fixed;
  left: 55px;
  width: 0;
  height: 100vh;
  overflow: hidden;
  /* border-right: 1px solid #23273d; */
  border-right: 0.3px solid #23273d;
  background-color: #f5ecd7;
  z-index: 99;
  /* display: none !important; */
  opacity: 0;
  transition: opacity 0.5s ease;
}
.card {
  border: none !important;
  background-color: #f8f8f6 !important;
  /* background-color: rgba(194, 186, 166, 0.2); */
  box-shadow:
    rgba(0, 0, 0, 0.25) 0px 14px 28px,
    rgb(0 0 0 / 0%) 0px 10px 10px;
}

.table {
  /* --bs-table-bg: #f5ecd7; */
  background-color: rgba(255, 255, 255, 0.2);
  --bs-table-bg: rgba(255, 255, 255, 0.2);
}

/* .rs-picker:focus {
  outline: 3px solid rgb(73 135 53 / 25%) !important;
} */

.rs-input {
  /* background-color: #f5ecd7 !important; */
  /* background-color: rgba(194, 186, 166, 0.2); */
}

.rs-input:focus,
.rs-input:hover:not(:disabled) {
  border-color: #45bf86 !important;
}

.rs-input:focus {
  outline: 3px solid rgb(107 231 130 / 25%) !important;
}

.rs-picker-default .rs-btn {
  /* background-color: #f5ecd7 !important; */
  /* background-color: rgba(194, 186, 166, 0.2) !important; */
}

.rs-picker.rs-picker-focused,
.rs-picker:not(.rs-picker-disabled):hover {
  border-color: #45bf86;
}

.rs-picker-has-value .rs-btn .rs-picker-toggle-value,
.rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
  color: #353535;
}

.rs-picker-select-menu-item.rs-picker-select-menu-item-active {
  color: #353535;
  background-color: #f8fffa;
}

.rs-picker-toggle-active,
.rs-picker.rs-picker-focused {
  box-shadow: 0 0 0 2px rgba(148, 255, 144, 0.25);
}

.rs-picker-select-menu-item.rs-picker-select-menu-item-focus,
.rs-picker-select-menu-item:focus,
.rs-picker-select-menu-item:hover {
  color: #353535;
  background-color: #eefff3;
}
/* 3px solid rgb(107 231 130 / 25%) */
.rs-picker-toggle-active,
.rs-picker.rs-picker-focused {
  box-shadow: 0 0 0 3px rgb(69, 191, 134/ 25%) !important;
}

.rs-input-group:focus-within,
.rs-input-group:not(.rs-input-group-disabled).rs-input-group-focus,
.rs-input-group:not(.rs-input-group-disabled):hover {
  border-color: #45bf86;
}

.rs-input-group:focus-within,
.rs-input-group:not(.rs-input-group-disabled).rs-input-group-focus {
  outline: #45bf86;
}

.rs-btn-primary {
  background-color: #e629cc;
  background-color: #45bf86;
}

.rs-btn-primary:hover,
.rs-btn-primary:focus {
  background-color: #50e6a0;
}

.rs-toggle-checked .rs-toggle-presentation:hover {
  background-color: #45bf86;
}

.rs-toggle-checked .rs-toggle-presentation {
  background-color: #45bf86;
}

.secondSidebarIcon {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid gold;
}

.categoryImage {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid gold;
}

.secondSidebarIcon svg {
  width: 20px;
  height: 20px;
}
/*
.secondSidebarIcon path {
  fill: white;
} */

.secondSidebar__categoryName {
  font-size: 0.9rem;
  font-weight: 700;
  margin-top: 1.25rem;
}

.secondSidebar__toolName {
  font-size: 0.8rem;
  list-style-type: none;
  padding: 0.5rem 0;
  color: white;
  /* margin-bottom: 0.6rem; */
}

.secondSidebar__toolName:hover {
  background-color: #0656bf;
  color: white !important;

  /* color: white !important; */
}

.secondSidebar__toolLink {
  color: #fff;
  /* color: #353535; */
  text-decoration: none;
  font-size: 1rem;
  /* white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; */
}

.selectedTool {
  background-color: #0656bf;

  /* font-weight: bold; */
}

.selectedTool .secondSidebar__toolLink {
  color: white !important;
}

.secondSidebar__toolLink:hover {
  color: white;
  text-decoration: none;
}

.secondSidebar__toolLink:focus {
  color: #353535;
  text-decoration: none;
}
